@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/close";

.TrackDisplay-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--padding);
  background-color: var(--bs-body-bg);

  max-height: var(--art-size);

  > .TrackDisplay-picture {
    flex: 1 0 var(--art-size);
    width: var(--art-size);
    height: var(--art-size);
    position: relative;
    color: rgba(128,128,128,0.8);
    background-color: #aaa;
  }

  > .TrackDisplay-picture > img {
    width: var(--art-size);
    height: var(--art-size);
  }

  > .TrackDisplay-details {
    flex: 1 1 100%;
  }

  > .TrackDisplay-details > .TrackDisplay-artist {
    font-weight: bold;
    line-height: 1;
  }

  > .TrackDisplay-details > .TrackDisplay-track {
    font-size: 120%;
    line-height: 1;
  }

  > .TrackDisplay-links {
    display: flex;
    padding: var(--padding);
    gap: min(calc(var(--art-size)/6), 22px);
  }

}

.TrackDisplay-wrapper {
  position: relative;

  @media not all and (hover: none) {
    &:hover .TrackDisplay-delete-button {
      opacity: 100%;
    }
  }

  .TrackDisplay-delete-button {
    @extend .btn-close;
    background-color: $red;
    padding: 0.5rem;
    border-radius: 1.5rem;
    overflow: visible;
    position: absolute;
    right: -0.5rem;
    top: -0.5rem;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.6);
    opacity: 0%;
    transition: opacity 0.1s ease-in-out;
    cursor: pointer;
    z-index: 100;
  }

}

.TrackDisplay-play-button {
  color: rgba(192,192,192,0.4);
  mix-blend-mode: hard-light;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}

.TrackDisplay-play-button:hover {
  color: rgba(192,192,192,0.9);
}

.TrackDisplay-external-player {
  color: var(--bs-body-color);
  opacity: 0.4;
  transition: opacity ease-in-out 0.15s;
  display: inline-block;
  width: min(calc(var(--art-size)/3), 44px);
  height: min(calc(var(--art-size)/3), 44px);

  @media (prefers-color-scheme: dark) {
    filter: invert(1);
  }

  &.loading {
    --completion: 100%;
    mask-image: conic-gradient(black var(--completion), rgb(0 0 0 / 40%) var(--completion));
    mask-size: 100%;
  }

  & > img {
    vertical-align: baseline;
  }

  &.pulsing {
    animation: 1s ease-in-out infinite alternate pulse;
  }
}

@keyframes pulse {
  from {
    filter: opacity(30%);
  }

  to {
    filter: opacity(80%);
  }
}

@media only screen and (hover: none) {
  .TrackDisplay-external-player:active {
    opacity: 1.0;
    transition-duration: 0s;
  }
}

@media screen and (hover: hover) {
  .TrackDisplay-external-player:hover {
    opacity: 1.0;
  }
}