// Custom.scss

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here

// Create your own map
$dark: #191414;

// 3. Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

#shortlist-preview {
  display: flex;
  flex-wrap: wrap;
  gap: var(--padding);
  padding: var(--padding) 0;
  justify-content: center;

  > div {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 360px;
    }
  }

  .TrackDisplay-container {
    font-size: 0.85rem;
    background-color: $indigo-100;
    overflow: visible;
    --art-size: 5rem;
  }

  .TrackDisplay-picture {
    overflow: hidden;
  }

  @media (prefers-color-scheme: dark) {
    .TrackDisplay-container {
      background-color: $indigo-800;
    }
  }
}
