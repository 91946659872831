// Custom.scss

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here

// Create your own map
$dark: #191414;

// 3. Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/root";

// 4. Include any optional Bootstrap CSS as needed

@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/close";

@media not all and (hover: hover) {
  .btn {
    &:hover {
      color: var(--#{$prefix}btn-color);
      text-decoration: if($link-decoration == none, null, none);
      background-color: var(--#{$prefix}btn-bg);
      border-color: var(--#{$prefix}btn-border-color);
    }
  }
}

.btn-fg {
  @include button-variant(
    $dark,
    $dark,
    $hover-background: shade-color($dark, $btn-hover-bg-shade-amount),
    $hover-border: shade-color($dark, $btn-hover-border-shade-amount),
    $active-background: shade-color($dark, $btn-active-bg-shade-amount),
    $active-border: shade-color($dark, $btn-active-border-shade-amount)
  );
}

.btn-outline-fg {
  @include button-outline-variant($dark);
}

@media (prefers-color-scheme: dark) {
  :root {
    --bs-body-color-rgb: #{to-rgb($light)} !important;
    --bs-body-bg-rgb: #{to-rgb($gray-800)} !important;

    --bs-body-bg: #{$gray-800} !important;
    --bs-body-color: #{$light} !important;

    --#{$prefix}link-color: #{$blue-400} !important;
    --#{$prefix}link-hover-color: #{$blue-300} !important;
  }

  .btn-outline-primary {
    --bs-btn-color: #{$blue-400};
    --bs-btn-border-color: #{$blue-400};
  }

  .btn-fg {
    @include button-variant(
      $light,
      $light,
      $hover-background: tint-color($light, $btn-hover-bg-tint-amount),
      $hover-border: tint-color($light, $btn-hover-border-tint-amount),
      $active-background: tint-color($light, $btn-active-bg-tint-amount),
      $active-border: tint-color($light, $btn-active-border-tint-amount)
    );
  }

  .btn-outline-fg {
    @include button-outline-variant($light);
  }
}

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "~bootstrap/scss/utilities/api";

// 6. Add additional custom code here

:root {
  --padding: 1rem;
  --art-size: 10rem;
  @include media-breakpoint-down(lg) {
    --art-size: 6.5rem;
  }
  @include media-breakpoint-down(md) {
    --padding: 0.25rem;
    --art-size: 5rem;
  }
}

html, body {
  height: 100%;
}

body {
  padding: var(--padding);
}

.main-title {
  @extend .h5;
  margin: calc(var(--padding) * -1);
  padding: calc(var(--padding) / 2) var(--padding);
  border-bottom: 1px solid $gray-300;
  margin-bottom: calc(var(--padding) / 2);
}

.nav-link {
  @extend .h2;
  flex: 1 1 100%;

  &.nav-link-active {
    text-decoration: none;
    color: var(--bs-body-color);
    font-weight: 700;
  }
}

.button-row {
  display: flex;
  gap: var(--padding);

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  > * {
    flex: 1 1 100%;
  }
}

.svg-button {
  display: inline-flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;

  > svg {
    height: 1.25rem;
  }

  &.btn-lg > svg {
    height: 2rem;
  }

  &.btn-sm > svg {
    height: 1.0rem;
  }

}

.btn-group > .btn.dropdown-toggle-split {
  flex: 0 1 auto;
}

.longlist-track-display {
  font-size: calc(0.85rem + 1.5vw);
}

.input-max-width {
  max-width: 30rem;
  margin: auto;
}

h1 {
  a:link, a:visited {
    color: $dark;
    &:not(:hover) {
      text-decoration: none;
    }
  }
}
