// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

@mixin sbs($color, $active-color) {
  background-color: $color;
  padding: var(--padding);
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;

  .TrackDisplay-container {
    transition: background-color 0.2s ease-in-out;
    background-color: $color;
  }

  @media (hover: none) {
    &:active {
      background-color: $active-color;

      .TrackDisplay-container {
        background-color: $active-color;
      }
    }
  }

  @media not all and (hover: none) {
    &:hover, &:active {
      background-color: $active-color;

      .TrackDisplay-container {
        background-color: $active-color;
      }
    }
  }
}

.side-by-side {
  display: flex;
  flex-direction: row;
  flex: 1 1 100%;
  gap: var(--padding);
  font-size: calc(0.85rem + 0.75vw);

  @media (max-aspect-ratio: 1/1) {
    flex-direction: column;
  }

  > * {
    flex: 1 1 100%;
    border-radius: var(--padding);
  }

  .sbs-left {
    @include sbs($pink-300, $pink-400);
  }

  .sbs-right {
    @include sbs($teal-300, $teal-400);
  }

  @media (prefers-color-scheme: dark) {
    .sbs-left {
      @include sbs($purple-600, $purple-500);
    }

    .sbs-right {
      @include sbs($green-600, $green-500);
    }
  }
}

.Matchup-top-10 {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  --td-bg-color: #{$blue-100};

  @media (prefers-color-scheme: dark) {
    --td-bg-color: #{$blue-600};
  }

  .TrackDisplay-wrapper {
    height: 5rem;
    font-size: 0.85rem;
    background-color: var(--td-bg-color);

    .TrackDisplay-container {
      background-color: var(--td-bg-color);
    }
  }

  .top-10-track {
    position: relative;
    --art-size: 5rem;

    .track-confidence {
      display: flex;
      gap: 0.25rem;
      position: absolute;
      right: 0.25rem;
      bottom: 0.25rem;
      width: 6rem;

      > span {
        flex: 1 1 100%;
        display: block;
        background-repeat: no-repeat;
      }

      .win-weight {
        background-image: linear-gradient(to top, $green-300, $green-200);
      }

      .loss-weight {
        background-image: linear-gradient(to top, $red-300, $red-200);
      }
    }
  }

  .dummy-track {
    height: 5rem;
    display: grid;
    place-items: center;
    background-color: var(--td-bg-color);
    font-size: 150%;
    color: $gray-600;

    @media (prefers-color-scheme: dark) {
      color: $gray-400;
    }
  }
}

$undo-button-background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="black" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/><path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/></svg>');

.HistoryEntry-undo-button {
  background-image: escape-svg($undo-button-background-image);
  background-size: 1rem 1rem;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  pointer-events: all;
  vertical-align: middle;

  &:hover {
    background-color: $gray-300;
  }
}
