@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/close";

$MIN_SWIPE_DISTANCE: 120px;

.SwipeToDelete {
  position: relative;
  overflow-x: hidden;
  touch-action: pan-x;
}

.SwipeToDelete-content {
  z-index: 2;
  position: relative;
  transition: margin-left 250ms ease-in-out, margin-right 250ms ease-in-out;
  touch-action: none;
}

.SwipeToDelete-delete {
  z-index: 1;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: $red-500;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  touch-action: none;

  > button {
    color: $white;
    background: transparent;
    height: 100%;
    border: none;
    max-width: $MIN_SWIPE_DISTANCE;
    flex: 1 0 $MIN_SWIPE_DISTANCE;
    text-align: center;
    line-height: 1;
  }
}
